@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-1: spacing(1);
$spacing-1_5: spacing(1.5);

.mat-menu-content:not(:empty) {
  padding: 0 !important;
}

.rs-menu {
  min-width: 328px !important;
  border-radius: $spacing-1 !important;

  .rs-default,
  .rs-bordered {
    border: 1px solid color-outline();
    border-radius: $spacing-1 !important;

    .rs-menu-title,
    rs-menu-title,
    rs-menu-item:first-of-type .rs-menu-item {
      border-top-left-radius: $spacing-1 !important;
      border-top-right-radius: $spacing-1 !important;
    }

    rs-menu-item:last-of-type {
      &,
      & .rs-menu-item {
        border-bottom-left-radius: $spacing-1 !important;
        border-bottom-right-radius: $spacing-1 !important;
      }
    }
  }

  .rs-bordered rs-menu-item:not(:last-of-type) {
    border-bottom: 1px solid color-outline();
  }
}

.rs-menu-item,
.rs-menu-title {
  display: flex !important;
  align-items: center !important;
  padding: $spacing-1_5 !important;
  background: color-surface() !important;
  line-height: 24px !important;
}

.rs-menu-title {
  height: 48px !important;
}

rs-menu-item,
rs-menu-title {
  height: fit-content !important;
}

.rs-menu-item {
  gap: $spacing-1;
  height: 56px !important;

  &:focus {
    outline: 4px solid color-outline_focus();
    outline-offset: -4px;
  }

  mat-icon {
    @include size(24px !important, 24px !important);

    margin-right: 0 !important;
  }

  mat-icon + span {
    font-weight: 500;
  }

  &__text {
    @include font(
      16px !important,
      500 !important,
      color-on_surface() !important
    );

    display: flex;
    flex: 1;
    gap: $spacing-1;
  }

  &__description {
    @include font(
      14px !important,
      400 !important,
      color-on_surface_variant() !important
    );

    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.035px;
  }

  &--selected {
    background: color-on_surface() !important;

    mat-icon svg path {
      fill: color-surface() !important;
    }

    .rs-menu-item__text {
      color: color-surface() !important;
    }
  }
}

.rs-menu__backdrop-for-overlapping + div {
  margin-top: -12px;
}
