/* stylelint-disable */
@function library-color($red, $green, $blue, $opacity) {
  @if $opacity == 100% {
    @return #{'rgb(#{$red, $green, $blue})'};
  } @else {
    @return #{'rgba(#{$red, $green, $blue, 1 * $opacity})'};
  }
}
/* stylelint-enable */

// brand
@function color-brand($opacity: 100%) {
  @return library-color(51, 51, 51, $opacity);
}

// primary
@function color-primary($opacity: 100%) {
  @return library-color(51, 51, 51, $opacity);
}

@function color-on_primary($opacity: 100%) {
  @return library-color(255, 255, 255, $opacity);
}

@function color-primary_container($opacity: 100%) {
  @return library-color(247, 247, 247, $opacity);
}

@function color-on_primary_container($opacity: 100%) {
  @return library-color(31, 24, 24, $opacity);
}

// secondary
@function color-secondary($opacity: 100%) {
  @return library-color(51, 51, 51, $opacity);
}

@function color-on_secondary($opacity: 100%) {
  @return library-color(255, 255, 255, $opacity);
}

@function color-secondary_container($opacity: 100%) {
  @return library-color(247, 247, 247, $opacity);
}

@function color-on_secondary_container($opacity: 100%) {
  @return library-color(31, 24, 24, $opacity);
}

// outline colors
@function color-outline($opacity: 100%) {
  @return library-color(134, 134, 139, $opacity);
}

@function color-outline_focus($opacity: 100%) {
  @return library-color(0, 40, 215, $opacity);
}

@function color-outline_select($opacity: 100%) {
  @return library-color(51, 51, 51, $opacity);
}

@function color-outline_variant($opacity: 100%) {
  @return library-color(242, 242, 242, $opacity);
}

// surface colors
@function color-surface($opacity: 100%) {
  @return library-color(255, 255, 255, $opacity);
}

@function color-surface_focus($opacity: 100%) {
  @return library-color(0, 40, 215, $opacity);
}

@function color-surface_select($opacity: 100%) {
  @return library-color(51, 51, 51, $opacity);
}

@function color-surface_variant($opacity: 100%) {
  @return library-color(247, 247, 247, $opacity);
}

@function color-on_surface_variant($opacity: 100%) {
  @return library-color(117, 117, 117, $opacity);
}

@function color-surface_variant-2($opacity: 100%) {
  @return library-color(245, 230, 230, $opacity);
}

@function color-surface_variant-3($opacity: 100%) {
  @return library-color(205, 205, 205, $opacity);
}

// on surface
@function color-on_surface($opacity: 100%) {
  @return library-color(51, 51, 51, $opacity);
}

@function color-on_surface-select($opacity: 100%) {
  @return library-color(247, 247, 247, $opacity);
}

@function color-on_surface-variant($opacity: 100%) {
  @return library-color(155, 155, 155, $opacity);
}

@function color-on_surface-variant-2($opacity: 100%) {
  @return library-color(102, 93, 93, $opacity);
}

@function color-on_surface-variant-3($opacity: 100%) {
  @return library-color(255, 255, 255, $opacity);
}

@function color-on_surface-variant-4($opacity: 100%) {
  @return library-color(117, 117, 117, $opacity);
}

@function color-on_surface-variant-5($opacity: 100%) {
  @return library-color(112, 112, 112, $opacity);
}

@function color-on_surface_select($opacity: 100%) {
  @return library-color(247, 247, 247, $opacity);
}

// warning
@function color-on_warning($opacity: 100%) {
  @return library-color(255, 255, 255, $opacity);
}

@function color-warning($opacity: 100%) {
  @return library-color(199, 80, 0, $opacity);
}

@function color-warning_container($opacity: 100%) {
  @return library-color(255, 228, 209, $opacity);
}

@function color-on_warning_container($opacity: 100%) {
  @return library-color(189, 78, 0, $opacity);
}

// success
@function color-success($opacity: 100%) {
  @return library-color(70, 164, 0, $opacity);
}

@function color-on_success($opacity: 100%) {
  @return library-color(255, 255, 255, $opacity);
}

@function color-on_success_container($opacity: 100%) {
  @return library-color(54, 112, 11, $opacity);
}

@function color-success_container($opacity: 100%) {
  @return library-color(226, 240, 203, $opacity);
}

// error
@function color-error($opacity: 100%) {
  @return library-color(231, 53, 53, $opacity);
}

@function color-error-2($opacity: 100%) {
  @return library-color(231, 22, 4, $opacity);
}

@function color-on_error_container($opacity: 100%) {
  @return library-color(194, 43, 43, $opacity);
}

@function color-error_container($opacity: 100%) {
  @return library-color(254, 231, 231, $opacity);
}

// information
@function color-information($opacity: 100%) {
  @return library-color(97, 95, 95, $opacity);
}

@function color-on_information_container($opacity: 100%) {
  @return library-color(87, 84, 84, $opacity);
}

@function color-information_container($opacity: 100%) {
  @return library-color(235, 235, 235, $opacity);
}

// elevation
@function color-elevation-1($opacity: 100%) {
  @return library-color(16, 16, 16, $opacity);
}

// progress
@function color-progress($opacity: 100%) {
  @return library-color(0, 167, 239, $opacity);
}

// dining
@function color-dining() {
  @return library-color(6, 119, 93, 100%);
}
